import React from 'react'
import ShapeApproved1 from '../../images/ShapeApproved1.svg'
import ShapeApproved2 from '../../images/ShapeApproved2.svg'
import ShapeApproved3 from '../../images/ShapeApproved3.svg'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import { StaticImage } from 'gatsby-plugin-image'

export function DoctorsPhotosRow() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }

    return (
        <Row>
            <FlipBox>
                <FlipBoxInner className="flip-box-inner">
                    <Figure className="flip-front">
                        <ShapeApproved1 />
                        <DoctorImageContainer>
                            <StaticImage
                                src="../../images/grabowski.jpg"
                                alt={t(
                                    'common_doctor_photo_row_professional_name_1'
                                )}
                            />
                        </DoctorImageContainer>
                        <span>
                            {t('common_doctor_photo_row_professional_name_1')}
                        </span>
                        <span>
                            {t(
                                'common_doctor_photo_row_professional_speciality_1'
                            )}
                        </span>
                    </Figure>
                    <FigureBack>
                        <Description>
                            {t('common_doctor_photo_row_professional_info_1')}
                        </Description>
                    </FigureBack>
                </FlipBoxInner>
            </FlipBox>
            <Figure>
                <ShapeApproved2 />
                <DoctorImageContainer>
                    <StaticImage
                        src="../../images/balsam.jpg"
                        alt={t('common_doctor_photo_row_professional_name_2')}
                    />
                </DoctorImageContainer>
                <span>{t('common_doctor_photo_row_professional_name_2')}</span>
                <span>
                    {t('common_doctor_photo_row_professional_speciality_2')}
                </span>
            </Figure>
            <Figure>
                <ShapeApproved3 />
                <DoctorImageContainer>
                    <StaticImage
                        src="../../images/kotlowski.jpg"
                        alt={t('common_doctor_photo_row_professional_name_3')}
                    />
                </DoctorImageContainer>
                <span>{t('common_doctor_photo_row_professional_name_3')}</span>
                <span>
                    {t('common_doctor_photo_row_professional_speciality_3')}
                </span>
            </Figure>
        </Row>
    )
}

const FlipBox = styled.div`
    background-color: transparent;
    width: 342px;
    perspective: 1000px;
    :hover {
        .flip-box-inner {
            box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
                0 1px 10px 0 rgba(0, 0, 0, 0.12),
                0 2px 4px -1px rgba(0, 0, 0, 0.2);
            transition: 0.5s;
        }

        .flip-front {
            opacity: 0;
        }
    }
    :hover {
        .flip-box-inner {
            transform: rotateY(180deg);
        }
    }
`

const FlipBoxInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
`

const Figure = styled.div`
    opacity: 1;
    width: 342px;
    height: 481px;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 30vw;
    transition: all 0.3;
    svg {
        position: absolute;
        left: 0;
        top: -60px;
        width: 342px;
        height: 342px;
    }
    span:nth-of-type(1) {
        font-size: 17px;
        line-height: 21px;
        font-weight: 800;
        color: #2e2f45;
        margin-bottom: 16px;
    }
    span:nth-of-type(2) {
        font-size: 16px;
        line-height: 20px;
        color: #8f9fa9;
    }
    @media (max-width: 900px) {
        max-width: 100vw;
        height: 380px;
        svg {
            top: -86px;
            width: 210px;
            left: calc(50% - 106px);
        }
        span:nth-of-type(1) {
            margin-bottom: 6px;
        }
    }
    @media (min-width: 901px) and (max-width: 1130px) {
        transform: scale(0.8);
        svg {
            left: calc(50% - 172px);
        }
    }
`

const FigureBack = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    color: black;
    transform: rotateY(180deg);
    text-align: center;
    background-color: white;
    padding: 20px;
`

const Description = styled.span`
    font-size: 16px;
    line-height: 20px;
    color: #8f9fa9;
    vertical-align: middle;
`

const Row = styled.div`
    max-width: 1280px;
    display: Grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 5.8rem auto;
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
        margin: 40px auto;
    }
`

const DoctorImageContainer = styled.div`
    width: 230px;
    margin: 50px auto;
    margin-bottom: 40px;
    @media (max-width: 900px) {
        width: 135px;
    }
`
