import styled from 'styled-components'

export const SectionTitle = styled.h2`
    padding: 0 24px;
    text-align: center;
    max-width: 800px;
    margin: 0 auto 120px auto;
    @media (max-width: 1330px) {
        margin-bottom: 0;
        font-size: 36px;
        line-height: 45px;
    }
    @media (max-width: 900px) {
        font-size: 36px;
        line-height: 45px;
        span {
            padding-right: 6px;
        }
    }
`
