import React from 'react'
import styled from 'styled-components'
import UKFlag from '../../../../images/uk.svg'
import PLFlag from '../../../../images/pl.svg'
import DEFlag from '../../../../images/de.svg'

import { changeLocale, injectIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { colors } from '../../../../utils/const'

const SelectLanguageMobile = ({ handleToggle }) => {
    return (
        <SelectLanguageWrapper>
            <div
                role="button"
                tabIndex="0"
                onClick={() => {
                    handleToggle()
                    changeLocale('en')
                }}
                onKeyDown={() => {
                    handleToggle()
                    changeLocale('en')
                }}
            >
                <UKFlag />
                <span>
                    <FormattedMessage id="common_header_eng" />
                </span>
            </div>

            <div
                role="button"
                tabIndex="0"
                onClick={() => {
                    handleToggle()
                    changeLocale('pl')
                }}
                onKeyDown={() => {
                    handleToggle()
                    changeLocale('pl')
                }}
            >
                <PLFlag />
                <span>
                    <FormattedMessage id="common_header_pl" />
                </span>
            </div>
            <div
                role="button"
                tabIndex="0"
                onClick={() => {
                    handleToggle()
                    changeLocale('de')
                }}
                onKeyDown={() => {
                    handleToggle()
                    changeLocale('de')
                }}
            >
                <DEFlag />
                <span>
                    <FormattedMessage id="common_header_de" />
                </span>
            </div>
        </SelectLanguageWrapper>
    )
}

const SelectLanguageWrapper = styled.div`
    color: ${colors.primaryDark};
    font-weight: bold;
    margin-top: 60px;
    display: flex;
    justify-content: start;
    align-items: center;
    div {
        font-size: 18px;
        line-height: 23px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        &:hover {
            color: #fa104d;
        }
    }
    svg {
        height: 20px;
        max-width: 29px;
    }
    span {
        margin-left: 14px;
    }
`

export default injectIntl(SelectLanguageMobile)
