import React, { useState, useEffect } from 'react'
import Logo from '../../images/logo.svg'
import {
    MenuMobile,
    HeaderWrapper,
    DownloadBtn,
    HamburgerDownloadBtn,
    MenuDesktop,
    HamburgerButton,
} from './header.styled'
import { Location } from '@reach/router'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { navigate } from 'gatsby-plugin-intl'
import SelectLanguageDesktop from './selectLanguage/desktop'
import SelectLanguageMobile from './selectLanguage/mobile'
import { useIntl, Link } from 'gatsby-plugin-intl'

const MenuExpanded = props => {
    const { handleToggle, returnActive, open, pathname, navItems } = props
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    return (
        <MenuMobile>
            <div className={`${open ? 'open' : ''}`}>
                <ul>
                    {navItems.map((item, index) => {
                        const { name, link } = item
                        return (
                            <li key={index}>
                                <Link
                                    className={returnActive(pathname, link)}
                                    to={link}
                                    style={{
                                        textDecoration: `none`,
                                    }}
                                    onClick={handleToggle}
                                >
                                    {name}
                                </Link>
                            </li>
                        )
                    })}
                    <li>
                        <HamburgerDownloadBtn
                            onClick={() => {
                                navigate('/#download')
                                handleToggle()
                            }}
                        >
                            {t('common_header_download_button')}
                        </HamburgerDownloadBtn>
                    </li>
                    <li>
                        <SelectLanguageMobile handleToggle={handleToggle} />
                    </li>
                </ul>
            </div>
        </MenuMobile>
    )
}

export const Header = () => {
    const [open, setOpen] = useState(false)
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }

    const navItems = [
        { name: t('common_header_for_patient_link'), link: '/' },
        { name: t('common_header_for_doctor_link'), link: '/for-doctor/' },
        { name: t('common_header_team_link'), link: '/team/' },
        { name: t('common_header_contact_link'), link: '/contact/' },
    ]

    const handleToggle = () => {
        if (open) {
            setOpen(false)
            document.documentElement.style.overflow = 'auto'
            document.body.style.overflow = 'auto'
        } else {
            setOpen(true)
            document.documentElement.style.overflow = 'hidden'
            document.body.style.overflow = 'hidden'
        }
    }

    const returnActive = (pathname, link) => {
        if (
            (link.includes(pathname) && pathname !== '/') ||
            (link === '/' && pathname === '/')
        )
            return 'active'
    }

    const returnHeaderColor = pathname => {
        if (isHeaderWhite) return '#fff'
        switch (pathname) {
            case '/for-doctor/':
                return '#e8fbf8'
            case '/team/':
                return '#f3efff'
            case '/contact/':
                return '#f3efff'
            case '/faq/':
                return '#EEF2F5'
            default:
                return '#fcfcfd'
        }
    }

    const shouldChangeColors = pathname => {
        return pathname === '/' && !stickyNav ? false : true
    }

    const [stickyNav, setStickyNav] = useState(false)
    const [isHeaderWhite, setHeaderWhite] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll)
        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, [])

    const listenToScroll = () => {
        if (window.pageYOffset > 0) {
            setStickyNav(true)
        } else {
            setStickyNav(false)
        }
        if (window.pageYOffset > 650) {
            setHeaderWhite(true)
        } else {
            setHeaderWhite(false)
        }
    }

    return (
        <Location>
            {locationProps => {
                const fullPathname = locationProps.location.pathname
                const pathname = fullPathname.slice(3, fullPathname.length)

                return (
                    <HeaderWrapper
                        stickyNav={stickyNav ? 1 : 0}
                        color={returnHeaderColor(pathname)}
                    >
                        <div>
                            <Link to="/">
                                <Logo />
                            </Link>
                            <MenuDesktop>
                                {navItems.map((item, index) => {
                                    const { name, link } = item
                                    return (
                                        <Link
                                            className={returnActive(
                                                pathname,
                                                link
                                            )}
                                            key={index}
                                            to={link}
                                            style={{
                                                textDecoration: `none`,
                                            }}
                                        >
                                            {name}
                                        </Link>
                                    )
                                })}
                            </MenuDesktop>
                            <HamburgerButton onClick={handleToggle}>
                                <div className={open ? 'open' : ''}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </HamburgerButton>
                            <DownloadBtn
                                isPurple={true}
                                onClick={() => {
                                    pathname === '/'
                                        ? scrollTo('#download')
                                        : navigate('/#download')
                                }}
                            >
                                {t('common_header_download_button')}
                            </DownloadBtn>
                            <SelectLanguageDesktop
                                isWhiteSymbol={shouldChangeColors(pathname)}
                                mainColor={returnHeaderColor(pathname)}
                                locationProps={locationProps}
                            />
                        </div>

                        <MenuExpanded
                            pathname={pathname}
                            returnActive={returnActive}
                            open={open}
                            handleToggle={handleToggle}
                            navItems={navItems}
                        />
                    </HeaderWrapper>
                )
            }}
        </Location>
    )
}
