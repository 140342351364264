import React from 'react'
import PropTypes from 'prop-types'

export const DecorationStripe = props => {
    const { color } = props
    return (
        <svg width="29" height="28" viewBox="0 0 29 28" fill="none">
            <rect
                x="4.30328"
                y="0.267578"
                width="34"
                height="5"
                rx="2.5"
                transform="rotate(45 4.30328 0.267578)"
                fill={color}
            />
        </svg>
    )
}

DecorationStripe.propTypes = {
    color: PropTypes.string,
}
