import styled from 'styled-components'

/**
 * @type {import('styled-components').StyledComponent<'section', {}, {
 *      width?: string,
 *      maxWidth?: string,
 *      minHeight?: string,
 *      background?: string,
 * }>}
 */
export const Section = styled.section`
    width: ${p => p.maxWidth || '100%'};
    max-width: ${p => p.maxWidth || '1220px'};
    ${p => p.minHeight && `min-height: ${p.minHeight};`}
    margin: 0 auto;
    padding: 100px 0 20px 0;
    ${p => p.background && `background: ${p.background};`}
    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: middle;
        padding: 24px;
    }
`
