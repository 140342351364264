import styled, { keyframes } from 'styled-components'
import { colors } from '../../utils/const'
import { Button } from '../../components/button'

const fadeInRight = keyframes`
0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`
export const MenuMobile = styled.div`
    @media (min-width: 900px) {
        display: none !important;
    }
    > div {
        height: 100vmax;
        overflow: scroll !important;
        background: ${colors.lightGrey2};
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.35s, visibility 0.35s, height 0.35s;
        &.open {
            display: flex;
            justify-content: flex-start;
            padding-left: 24px;
            flex-direction: column;
            opacity: 1;
            visibility: visible;
            height: 100vmax;
            z-index: -1;
            li {
                text-align: start;
                animation: ${fadeInRight} 0.5s ease forwards;
                animation-delay: 0.35s;
                &:nth-of-type(2) {
                    animation-delay: 0.4s;
                }
                &:nth-of-type(3) {
                    animation-delay: 0.45s;
                }
                &:nth-of-type(4) {
                    animation-delay: 0.5s;
                }
                &:nth-of-type(5) {
                    animation-delay: 0.55s;
                }
                &:nth-of-type(6) {
                    animation-delay: 0.6s;
                }
            }
        }
        ul {
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            list-style: none;
            padding: 0;
            margin: 30vh 0 0 0;
            li {
                display: block;
                position: relative;
                opacity: 0;
                margin-bottom: 30px;
                cursor: pointer;
                &:hover {
                    color: ${colors.red};
                }
                a {
                    font-weight: 800;
                    text-decoration: none;
                    color: #2e2f45;
                    &:hover {
                        color: ${colors.red};
                    }
                    &.active {
                        &:after {
                            position: absolute;
                            bottom: calc(50% - 3px);
                            left: 120%;
                            content: '';
                            background: ${colors.red};
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
`

export const HeaderWrapper = styled.header`
    transition: 0.4s;
    position: ${props => (props.stickyNav ? 'fixed' : 'absolute')};
    background: ${props => (props.stickyNav ? props.color : 'transparent')};
    width: 100%;
    z-index: 10;
    /* height: ${props => (props.stickyNav ? '100px' : '110px')}; */
    top: 0;
    > div {
        padding: 10px 24px;
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        max-width: 1493px;
    }
    a {
        color: ${colors.menuDark};
        font-weight: bold;
        margin: 0 25px;
        position: relative;
        transition: 0.3s ease;
        &:first-of-type {
            margin-left: 0;
        }
        &.active {
            &:after {
                position: absolute;
                bottom: -10px;
                left: calc(50% - 3px);
                content: '';
                background: ${colors.red};
                width: 6px;
                height: 6px;
                border-radius: 50%;
            }
        }
        &:hover {
            color: ${colors.red};
        }
    }
    svg {
        transition: 0.4s;
        position: relative;
        z-index: 99;
        transform: ${props => (props.stickyNav ? 'scale(.95)' : 'scale(1)')};
    }
    @media (max-width: 900px) {
        > div {
            padding: ${props => (props.stickyNav ? '14px 24px' : '24px 24px')};
            justify-content: space-between;
        }
        padding: 0;
        height: 80px;
        svg {
            width: 133px;
            margin: 0;
        }
    }
`

export const DownloadBtn = styled.button`
    z-index: 10;
    font-size: 15px;
    font-weight: bold;
    width: 150px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    transition: 0.2s ease-in-out;
    border: ${props =>
        props.isPurple ? ' 2px solid #3D278A' : '2px solid #fff'};
    color: #fff;
    margin-left: auto;
    background: ${props => (props.isPurple ? '#3D278A' : 'transparent')};
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    @media (max-width: 900px) {
        display: none;
    }
    &:hover {
        background: ${props =>
            props.isPurple
                ? 'rgba(61, 39, 138, 0.85)'
                : 'rgba(255,255,255,0.3)'};
    }
`

export const HamburgerDownloadBtn = styled(Button)`
    margin-top: 60px;
`

export const MenuDesktop = styled.div`
    margin-left: 90px;
    @media (max-width: 900px) {
        display: none;
    }
    @media (max-width: 1080px) {
        margin: auto;
    }
`

export const HamburgerButton = styled.button`
    box-sizing: content-box;
    width: 9px;
    background: none;
    border: none;
    z-index: 10;
    cursor: pointer;
    height: 18px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 24px;
        background: #2b2b2c;
        border-radius: 1px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
    }
    span:nth-child(1) {
        top: 2px;
    }
    span:nth-child(2),
    span:nth-child(3) {
        top: 8px;
    }
    span:nth-child(4) {
        top: 14px;
    }
    .open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
    .open span:nth-child(2) {
        transform: rotate(45deg);
    }
    .open span:nth-child(3) {
        transform: rotate(-45deg);
    }
    .open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
    display: none;
    @media (max-width: 900px) {
        display: block;
    }
`
