import styled from 'styled-components'

export const Button = styled.button`
    border: 0;
    min-height: 56px;
    width: 216px;
    color: white;
    font-weight: 700;
    background: rgba(61, 39, 138, 1);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &[disabled] {
        cursor: default;
        background-color: grey;
    }
    &:not([disabled]):hover {
        background-color: rgba(61, 39, 138, 0.85);
    }

    @media (max-width: 900px) {
        font-size: 15px;
        line-height: 19px;
        width: 180px;
    }
`
