import React, { useState, useRef, useEffect, useCallback } from 'react'
import UKFlag from '../../../../images/uk.svg'
import PLFlag from '../../../../images/pl.svg'
import DEFlag from '../../../../images/de.svg'
import { changeLocale, injectIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { ArrowVertical } from './ArrowVertical'
import {
    SelectLanguageWrapper,
    LanguageTooltip,
    Button,
} from './desktop.styled'
import { returnButtonSelectLanguage } from './returnButtonSelectLanguage'

const SelectLanguageDesktop = ({ mainColor, locationProps, isWhiteSymbol }) => {
    const [open, setOpen] = useState(false)
    const node = useRef()
    const lang = locationProps.location.pathname.slice(1, 3)

    const handleClick = useCallback(
        e => {
            if (node.current.contains(e.target)) {
                return
            }
            if (open) {
                setOpen(false)
            }
        },
        [open]
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleClick)
        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [handleClick])

    const toggleOpen = () => {
        setOpen(!open)
    }

    return (
        <SelectLanguageWrapper
            open={open ? 1 : 0}
            ref={node}
            onClick={() => toggleOpen()}
        >
            <Button isWhiteSymbol={isWhiteSymbol ? 0 : 1}>
                {returnButtonSelectLanguage(lang)}
                {ArrowVertical(isWhiteSymbol)}
            </Button>
            <LanguageTooltip open={open ? 1 : 0} color={mainColor}>
                {lang !== 'en' && (
                    <div
                        role="button"
                        onClick={() => changeLocale('en')}
                        onKeyDown={() => {
                            changeLocale('en')
                        }}
                        tabIndex="0"
                    >
                        <UKFlag />
                        <FormattedMessage id="common_header_eng" />
                    </div>
                )}
                {lang !== 'pl' && (
                    <div
                        role="button"
                        onClick={() => changeLocale('pl')}
                        onKeyDown={() => {
                            changeLocale('pl')
                        }}
                        tabIndex="0"
                    >
                        <PLFlag /> <FormattedMessage id="common_header_pl" />
                    </div>
                )}
                {lang !== 'de' && (
                    <div
                        role="button"
                        onClick={() => changeLocale('de')}
                        onKeyDown={() => {
                            changeLocale('de')
                        }}
                        tabIndex="0"
                    >
                        <DEFlag /> <FormattedMessage id="common_header_de" />
                    </div>
                )}
            </LanguageTooltip>
        </SelectLanguageWrapper>
    )
}

export default injectIntl(SelectLanguageDesktop)
