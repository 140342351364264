import React from 'react'
import styled from 'styled-components'
import footerDecorator from '../../images/footer_decorator.svg'
import footerLogo from '../../images/footer_logo.svg'
import { useIntl, Link } from 'gatsby-plugin-intl'
// import { Link } from 'gatsby'
  import * as klaro from '../klaro/klaro.js';
 import { klaroConfig } from '../klaro/config.js';
 import "../klaro/klaro.css";
export function Footer() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }

    if (typeof window !== 'undefined') {
        window.onload = async function () {
          window.klaro = klaro;
          window.klaroConfig = klaroConfig;
          klaro.setup(klaroConfig);
          klaro.defaultConfig.lang=intl.locale;
          klaro.show(klaroConfig, false);
        };
      }
    
      const handleOnClick = async (e) => {
        e.preventDefault();
        await klaro.show(klaroConfig, false);
      };



    return (
        <FooterWrapper>
            <FooterContent>
                <div>
                    <LinkBox>
                        <Link to="/">
                            {t('common_footer_for_patient_link')}
                        </Link>
                        <Link to="/for-doctor">
                            {t('common_footer_for_doctor_link')}
                        </Link>
                        <Link to="/team">{t('common_footer_team_link')}</Link>
                    </LinkBox>
                    <FooterLogo />
                </div>
                <FooterDecorator />
                <GreyLine />
                <div>
                    <LinkBox>
                        <Link to="/contact">
                            {t('common_footer_form_link')}
                        </Link>
                        <a className={'klaro'} href="#" onClick={handleOnClick} >
        {t('common_footer_cookie_settings')}
        </a>
                        <Link to="/privacy-policy">
                            {t('common_footer_privacy_policy_link')}
                        </Link>
                        <Link to="/faq">FAQ</Link>
                        {/* TO_DO WAITING FOR BLOG */}
                        {/* <Link to="/blog">{t("common_doctor_photo_row_professional_name_2")}Blog</Link> */}
                    </LinkBox>
                    <AllbrightTag
                        href="https://allbright.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('common_footer_made_by')}
                    </AllbrightTag>
                </div>
            </FooterContent>
        </FooterWrapper>
    )
}

const FooterLogo = styled(footerLogo)`
    display: flex;
    justify-self: flex-end;
    @media (max-width: 770px) {
        width: 97px;
        height: 97px;
    }
`

const FooterDecorator = styled(footerDecorator)`
    @media (max-width: 770px) {
        margin-top: 80px;
    }
`

const FooterWrapper = styled.footer`
    background: #3d278a;
    min-height: 400px;
`

const FooterContent = styled.footer`
    padding: 100px 24px 80px;
    max-width: 1445px;
    margin: 0 auto;
    a {
        transition: border 0.4s ease-in-out;
        color: white;
        text-decoration: none;
        margin-right: 60px;
        border-bottom: 1px solid transparent;
        &:hover {
            border-bottom: 1px solid white;
        }
    }
    > div {
        display: flex;
        justify-content: space-between;
        &:nth-of-type(1) {
            a {
                height: 22px;
                font-weight: 700;
            }
        }
        &:nth-of-type(2) {
            font-size: 15px;
        }
    }
    > div:nth-of-type(2) {
        @media (max-width: 770px) {
            flex-direction: column;
        }
    }
    @media (max-width: 770px) {
        padding: 60px 24px 40px;
    }
`

const GreyLine = styled.hr`
    background: #c7c7c7;
    opacity: 0.25;
    margin: 40px 0;
    @media (max-width: 770px) {
        margin: 30px 0;
    }
`

const AllbrightTag = styled.a`
    margin-right: 0 !important;
    font-size: 15px;
    padding-right: 28px;
    color: white;
    position: relative;
    &:after {
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        background: white;
        border-radius: 50%;
        right: 0;
        top: 6px;
    }
    @media (max-width: 770px) {
        margin-top: 68px;
        padding-left: 30px;
        &:after {
            position: absolute;
            content: '';
            width: 8px;
            height: 8px;
            background: white;
            border-radius: 50%;
            left: 0;
            top: 6px;
        }
    }
`

const LinkBox = styled.div`
    display: flex;
    @media (max-width: 770px) {
        flex-direction: column;
        a {
            margin-bottom: 20px;
        }
    }
`
