export const colors = {
    menuDark: '#223357',
    red: '#FA104D',
    primaryDark: '#2E2F45',
    mediumGrey: '#8f9fa9',
    lightGrey: '#C7C7C7',
    purple: '#3D278A',
    lightGrey2: '#EEF2F5',
    darkTheme: {
        background: '#1E242C',
        text: '#FFFFFF',
        tileBackground: '#2A333D',
    },
}
