import React from 'react'
import UKFlag from '../../../../images/uk.svg'
import PLFlag from '../../../../images/pl.svg'
import DEFlag from '../../../../images/de.svg'
import { FormattedMessage } from 'gatsby-plugin-intl'

export const returnButtonSelectLanguage = lang => {
    if (lang === 'en') {
        return (
            <>
                <UKFlag />
                <span>
                    <FormattedMessage id="common_header_eng" />
                </span>
            </>
        )
    }
    if (lang === 'pl') {
        return (
            <>
                <PLFlag />
                <span>
                    <FormattedMessage id="common_header_pl" />
                </span>
            </>
        )
    }
    if (lang === 'de') {
        return (
            <>
                <DEFlag />
                <span>
                    <FormattedMessage id="common_header_de" />
                </span>
            </>
        )
    }
}
