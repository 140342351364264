import styled from 'styled-components'
import { colors } from '../../../../utils/const'

export const SelectLanguageWrapper = styled.div`
    font-weight: bold;
    position: relative;
    height: auto !important;
    button {
        color: ${colors.primaryDark};
        width: 100px;
        margin-left: 40px;
        padding: 10px 0;
        cursor: pointer;
        background: transparent;
        border: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        svg:nth-of-type(1) {
            height: 16px;
            width: 50px;
        }
        svg:nth-of-type(2) {
            margin-left: 9px;
            transform: ${props => (props.open ? 'scaleY(-1)' : 'scaleY(1)')};
        }
        span {
            width: 38px;
        }
    }
    @media (max-width: 900px) {
        display: ${props => (props.menuExpanded ? 'block' : 'none')};
    }
    @media (max-width: 1080px) {
        button {
            margin-left: 10px;
        }
    }
`

export const LanguageTooltip = styled.div`
    box-sizing: border-box;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
    border: ${props => (props.open ? '1px solid #e0e3e8' : 'none')};
    background: white;
    transition: 0.14s ease-in-out;
    position: absolute;
    top: 55px;
    left: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: ${props => (props.open ? '80px' : '0')};
    border-radius: 4px;
    overflow: hidden;
    div {
        border-top: 1px solid #e0e3e8;
        border-bottom: 4px;
        cursor: pointer;
        align-items: center;
        width: 100px;
        display: flex;
        padding: 10px 5px 10px 5px;
        &:first-of-type {
            padding: 10px 5px;
            border-top: none;
        }
        svg {
            margin-right: 18px;
            width: 30px;
            cursor: pointer;
            height: 16px;
            opacity: 0.9;
        }
        &:hover {
            color: ${colors.red};
        }
        span {
            width: 30px;
        }
    }
`

export const Button = styled.button`
    span {
        color: ${props => (props.isWhiteSymbol ? 'white' : colors.menuDark)};
        filter: ${props =>
            props.isWhiteSymbol
                ? 'drop-shadow(0px 4px 4px rgba(0,0,0,0.25))'
                : 'none'};
        &:hover {
            color: ${props =>
                props.isWhiteSymbol ? 'rgba(255, 255, 255, 0.88)' : colors.red};
        }
    }
`
