import React, { useState, useEffect, useRef } from 'react'
import Cookies from 'universal-cookie'
import styled from 'styled-components'
import { useIntl, Link } from 'gatsby-plugin-intl'
// import { Link } from 'gatsby'

export function CookieBanner() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    const [isVisible, setVisible] = useState(false)
    const [cookieBorder, setCookieBorder] = useState(false)

    const cookies = useRef(null)
    cookies.current = new Cookies()

    useEffect(() => {
        const isAcceppted = cookies.current.get('acceptted')
        isAcceppted ? setVisible(false) : setVisible(true)
        console.log(isAcceppted)
    }, [cookies])

    const handleCookies = () => {
        const expireDate = new Date()
        expireDate.setMonth(expireDate.getMonth() + 8)
        cookies.current.set('acceptted', 'true', {
            path: '/',
            expires: expireDate,
        })
        setVisible(false)
    }

    useEffect(() => {
        console.log('hello')
        window.addEventListener('scroll', listenToScroll)
        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, [])

    const listenToScroll = () => {
        if (window.pageYOffset > 0) {
            setCookieBorder(true)
        } else {
            setCookieBorder(false)
        }
    }

    return (
        <Wrapper
            isVisible={isVisible ? 1 : 0}
            cookieBorder={cookieBorder ? 1 : 0}
        >
            <div>
                <span>{t('home_cookies_dialog_message')} </span>
                <Link to="/privacy-policy">
                    {t('home_cookies_dialog_link')}
                </Link>
            </div>
            <button onClick={handleCookies}>
                {t('home_cookies_dialog_button')}
            </button>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    transition: border 0.4s ease-in-out;
    border: ${props =>
        props.cookieBorder ? '1px solid  #e0e3e8' : '1px  solid  transparent'};
    display: ${props => (props.isVisible ? 'block' : 'none')};
    position: fixed;
    bottom: 30px;
    right: 30px;
    min-width: 260px;
    min-height: 115px;
    background: #ffffff;
    z-index: 1000;
    border-radius: 4px;
    box-sizing: content-box;
    > div {
        padding: 26px 28px;
        font-size: 14px;
        line-height: 20px;
    }
    span {
        font-weight: 600;
        padding-right: 28px;
        position: relative;
        &:after {
            content: '';
            width: 4px;
            height: 4px;
            right: 12px;
            top: calc(50% - 2px);
            border-radius: 50%;
            background: #2e2f45;
            position: absolute;
        }
    }
    a {
        color: #8f9fa9;
        text-decoration: none;
        border-bottom: 1px solid transparent;
        transition: 0.2s ease-in-out;
        &:hover {
            border-bottom: 1px solid #8f9fa9;
        }
    }
    button {
        padding: 13px 0;
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        color: #2e2f45;
        font-weight: 900;
        background: none;
        border: none;
        border-top: 1px solid #e0e3e8;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
            color: #fa104d;
            background: none;
        }
    }
    @media (max-width: 900px) {
        width: 100%;
        bottom: 0;
        left: 0;
        border-radius: 0;
        > div {
            display: flex;
            justify-content: center;
        }
    }
`
